<template>
    <a
        href="#"
        @click.prevent="download"
    >
        <b-icon icon="cloud-download" aria-hidden="true"/>
    </a>
</template>

<script>
import download from 'downloadjs'

export default {
    name: 'DownloadLink',
    props: {
        id: [Number, String],
        from: String,
        url: String
    },
    computed: {
        getFileName() {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            return this.url.split('').reverse().join('').split('/')[0].split('').reverse().join('')
        }
    },
    methods: {
        // toDataURL (url, callback) {
        //     const xhr = new XMLHttpRequest()
        //     xhr.onload = () => {
        //         const reader = new FileReader()
        //         reader.onloadend = () => {
        //             callback(reader.result)
        //         }
        //         reader.readAsDataURL(xhr.response)
        //     }
        //     xhr.open('GET', url)
        //     xhr.responseType = 'blob'
        //     xhr.send()
        // },
        download() {
            this.$store.dispatch('downloadFile', {
                id: this.id,
                from: this.from
            }).then(res => {
                const status = res.status
                if (status === 204) {
                    this.error('Файл был удален.')
                }
                if (status === 200) {
                    const content = res.headers['content-type']
                    download(new Blob([res.data]), this.getFileName, content)
                }
            }).catch(err => {
                if (err.response?.status === 404) {
                    this.error('Файл был удален.')
                } else {
                    this.error()
                }
            })
        },
        error(text = 'Что-то пошло не так. Попробуйте позднее.') {
            this.$bvToast.toast(text, {
                toaster: 'b-toaster-top-right',
                solid: true,
                appendToast: false,
                variant: 'danger'
            })
        }
    }
}
</script>

<style
    lang="sass"
    scoped
>

</style>
