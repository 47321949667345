<template>
    <div class="create-price-negotiation">
        <div
            v-if="!pageLoad"
            class="page-loader">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <div class="page-title d-flex py-2 justify-content-between">

            <h1
                v-if="edit"
                class="h4 mb-0">
                Редактирование запроса на согласование цен <span v-if="negotiation">№ {{ negotiation.number }} от {{ negotiation.date | parseDate }}</span>
            </h1>
            <h1
                v-else
                class="h4 mb-0">
                Создание запроса на согласование цен
            </h1>

            <div class="d-flex align-items-center">
                <BButton
                    class="ml-3"
                    variant="danger"
                    v-b-modal.confirm-delete>Удалить
                </BButton>
                <BButton
                    class="ml-3"
                    variant="success"
                    v-bind:disabled="inProcess"
                    @click="createNegotiation(true)">Сохранить
                </BButton>
                <BButton
                    class="ml-3"
                    variant="primary"
                    v-bind:disabled="inProcess"
                    @click="createNegotiation()"
                >Отправить на согласование
                </BButton>
            </div>

        </div>

        <div class="mb-4">

            <div class="d-flex table-bordered mb-1">
                <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Тип запроса</div>
                <div class="px-2 py-1 flex-grow-1">
                    <div style="height: 29px; line-height: 29px;">
                        <div
                            v-if="isContractor"
                            style="height: 29px;line-height: 29px;"
                        >
                            {{ getType }}
                        </div>

                        <b-form-select
                            v-else
                            v-model="sendData.type"
                            :options="typeOptions"
                        />
                    </div>
                </div>
            </div>

            <div
                v-if="isContractor || ( isProvider && sendData.type === 'contract_work' )"
                class="d-flex table-bordered mb-1"
            >
                <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Объект</div>
                <div class="px-2 py-1 flex-grow-1">
                    <v-select
                        label="text"
                        v-bind:class="{
                        'v-select-error': ( $v.sendData.object_id && $v.sendData.object_id.$error ),
                        }"
                        v-bind:options="objectsOptions"
                        v-bind:value="
                        objectsOptions.find(
                            (el) => el.value === sendData.object_id
                        )
                        "
                        v-on:input="
                        (value) => (sendData.object_id = value.value)
                        "
                        :appendToBody="true"
                        :placeholder="
                        objectsOptions.find(
                            (el) => el.value === sendData.object_id
                        )
                            ? objectsOptions.find(
                                (el) => el.value === sendData.object_id
                            ).text
                            : ''
                        "
                        @search="getObjectsOptionsByQuery"
                    >
                        <template #no-options> Нет результатов. </template>
                    </v-select>
                </div>
            </div>

            <div
                v-if="isContractor"
                class="d-flex table-bordered mb-1"
            >
                <div
                    class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                    style="width: 250px;"
                >Подобъект
                </div>
                <div class="px-2 py-1 flex-grow-1">
                    <b-form-select
                        v-model="sendData.sub_object_id"
                        :options="subObjectsOptions(sendData.object_id)"
                    />
                </div>
            </div>

            <div class="d-flex table-bordered mb-1">
                <div
                    class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                    style="width: 250px;"
                >Поставщик
                </div>
                <div class="px-2 py-1 flex-grow-1">
                    <v-select
                        v-if="isContractor"
                        label="text"
                        v-bind:class="{
                        'v-select-error': $v.sendData.provider_contr_agent_id.$error,
                        }"
                        v-bind:appendToBody="true"
                        v-bind:options="contrAgentsOptions.slice(1)"
                        v-bind:placeholder="
                        contrAgentsOptions
                            .slice(1)
                            .find((el) => el.value === sendData.provider_contr_agent_id)
                            ? contrAgentsOptions
                                .slice(1)
                                .find(
                                (el) => el.value === sendData.provider_contr_agent_id
                                ).text
                            : ''
                        "
                        v-bind:value="
                        contrAgentsOptions
                            .slice(1)
                            .find((el) => el.value === sendData.provider_contr_agent_id)
                        "
                        v-on:input="
                        (value) => (sendData.provider_contr_agent_id = value.value)
                        "
                        @search="getContrAgentsOptionsByQuery"
                    >
                        <template #no-options> Нет результатов. </template>
                    </v-select>
                    <div
                        v-if="isProvider"
                        style="height: 29px;line-height: 29px;">
                        {{ user && user.contr_agent && user.contr_agent.name }}
                    </div>
                </div>
            </div>

            <div class="d-flex table-bordered mb-1"
                v-if="isContractor || ( isProvider && sendData.type === 'contract_work' )"
            >
            <!-- <div class="d-flex table-bordered mb-1"> -->
                <div
                    class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                    style="width: 250px;"
                >Подрядчик
                </div>
                <div class="px-2 py-1 flex-grow-1">
                    <div
                        v-if="isContractor"
                        style="height: 29px;line-height: 29px;">
                        {{ user && user.contr_agent && user.contr_agent.name }}
                    </div>
                    <v-select
                        v-else
                        label="text"
                        v-bind:class="{ 'v-select-error': $v.sendData.$error && $v.sendData.contractor_contr_agent_id && $v.sendData.contractor_contr_agent_id.required }"
                        v-bind:appendToBody="true"
                        v-bind:options="contrAgentsOptions.slice(1)"
                        v-bind:placeholder="contrAgentsOptions.slice(1).find( el => el.value === sendData.contractor_contr_agent_id ) ? contrAgentsOptions.slice(1).find( el => el.value === sendData.contractor_contr_agent_id ).text : ''"
                        v-bind:value="contrAgentsOptions.slice(1).find( el => el.value === sendData.contractor_contr_agent_id )"
                        v-on:input="value => ( sendData.contractor_contr_agent_id = value.value )"
                        @search="getContrAgentsOptionsByQuery"
                    >
                        <template #no-options>
                            Нет результатов.
                        </template>
                    </v-select>
                    <!-- <b-form-select
                        v-else
                        v-model="sendData.contractor_contr_agent_id"
                        :state="$v.sendData.$error ? $v.sendData.contractor_contr_agent_id.required : null"
                        :options="contrAgentsOptions.slice(1)"
                    /> -->
                </div>
            </div>

            <div class="d-flex table-bordered mb-1">
                <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Филиал</div>
                <div class="px-2 py-1 flex-grow-1">
                    <!-- <b-form-select
                        v-model="sendData.organization_id"
                        :state="$v.sendData.$error ? $v.sendData.organization_id.required : null"
                        :options="organizationsOptions.slice(1)"
                    /> -->
                    <v-select
                        label="text"
                        v-bind:class="{ 'v-select-error': $v.sendData.organization_id.$error }"
                        v-bind:appendToBody="true"
                        v-bind:options="organizationsOptions.slice(1)"
                        v-bind:placeholder="organizationsOptions.slice(1).find( el => el.value === sendData.organization_id ) ? organizationsOptions.slice(1).find( el => el.value === sendData.organization_id ).text : ''"
                        v-bind:value="organizationsOptions.slice(1).find( el => el.value === sendData.organization_id )"
                        v-on:input="value => ( sendData.organization_id = value.value )"
                        @search="getOrganizationsOptionsByQuery"
                    >
                        <template #no-options>
                        Нет результатов.
                        </template>
                    </v-select>
                </div>
            </div>

            <div class="d-flex table-bordered mb-1">
                <div
                    class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                    style="width: 250px;"
                >Заказ на поставку
                </div>
                <div class="px-2 py-1 flex-grow-1">
                    <b-form-select
                        v-model="sendData.order_id"
                        :options="orders"
                    />
                </div>
            </div>

            <div class="d-flex table-bordered mb-1">
                <div
                    class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                    style="width: 250px;"
                >№ договора
                </div>
                <div class="px-2 py-1 flex-grow-1">
                    <v-select
                        label="text"
                        v-bind:class="{ 'v-select-error': $v.sendData.work_agreement_id.$error }"
                        v-bind:appendToBody="true"
                        v-bind:options="workAgreementsOptions.slice(1)"
                        v-bind:placeholder="workAgreementsOptions.slice(1).find( el => el.value === sendData.work_agreement_id ) ? workAgreementsOptions.slice(1).find( el => el.value === sendData.work_agreement_id ).text : ''"
                        v-bind:value="workAgreementsOptions.slice(1).find( el => el.value === sendData.work_agreement_id )"
                        v-on:input="value => ( sendData.work_agreement_id = value.value )"
                        @search="getWorkAgreementsOptionsByQuery"
                    >
                        <template #no-options>
                        Нет результатов.
                        </template>
                    </v-select>
                </div>
            </div>

            <div class="d-flex table-bordered mb-1">
                <div
                    class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                    style="width: 250px;"
                >Страна
                </div>
                <div class="px-2 py-1 flex-grow-1">
                    <b-form-select
                        v-model="sendData.country"
                        :options="getCountriesOptions"
                    />
                </div>
            </div>

            <b-row>

                <b-col cols="6">
                    <div class="d-flex table-bordered mb-1">
                        <div
                            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                            style="width: 250px;"
                        >Ответственный
                        </div>
                        <div class="px-2 py-1 flex-grow-1">
                            <b-form-input
                                v-model="sendData.responsible_full_name"
                                :state="$v.sendData.$error ? $v.sendData.responsible_full_name.required : null"
                                trim/>
                        </div>
                    </div>
                </b-col>

                <b-col cols="6">
                    <div class="d-flex table-bordered mb-1">
                        <div
                            class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                            style="width: 250px;"
                        >Телефон
                        </div>
                        <div class="px-2 py-1 flex-grow-1">
                            <b-form-input
                                v-model="sendData.responsible_phone"
                                :state="$v.sendData.$error ? $v.sendData.responsible_phone.required : null"
                                v-mask="'+7 (###) ###-##-##'"
                                trim/>
                        </div>
                    </div>
                </b-col>

            </b-row>

            <div class="d-flex table-bordered mb-1">
                <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Комментарий</div>
                <div class="px-2 py-1 flex-grow-1">
                    <b-form-textarea
                        v-model.trim="sendData.comment"
                        :state="$v.sendData.$error ? $v.sendData.comment.required : null"
                        placeholder="Текст комментария"/>
                </div>
            </div>

            <div class="d-flex table-bordered mb-1">
                <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Приложение</div>
                <div class="px-2 py-1 flex-grow-1">
                    <b-form-file
                        multiple
                        v-model="sendData.files"
                        placeholder="Выберите файл"
                        plain
                    />
                    <div
                        v-if="fileUrl"
                        class="mt-2"
                    >
                        <!-- Ранее загруженный файл:
                        <DownloadLink
                            :id="id"
                            from="price-negotiations"
                            :url="fileUrl"
                        />
                        <div class="mt-1">
                            <a
                                href="#"
                                class="text-danger"
                                @click="clearFile"
                            >
                                <b-icon-trash/>
                                Удалить
                            </a>
                        </div> -->
                    </div>
                    <div
                        v-if="fileUrls"
                        class="mt-2"
                    >
                        Ранее загруженные файлы:
                        <div style="display: flex;">
                            <div style="padding-right: 20px;"
                                v-for="( fileUrl, idx ) of fileUrls"
                                v-bind:key="idx"
                            >
                                <DownloadLink
                                    :id="fileUrl.id"
                                    :from="`price-negotiations/${ id }/files`"
                                    :url="fileUrl.url"
                                />
                                <a style="padding-left: 10px;"
                                    href="#"
                                    class="text-danger"
                                    @click="delFile( fileUrl )"
                                >
                                    <b-icon-trash/>
                                    Удалить
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="mb-4">
            <div class="h4 mb-4">Позиция</div>

            <div class="table-create">
                <div class="mt-3">

                    <BTable
                        :items="positions"
                        :fields="positionsFields"
                        :bordered="true"
                        id="create-table"
                        :empty-text="'По выбранным данным отстутствуют номенклатуры.'"
                        striped
                        small
                        show-empty
                    >

                        <template #table-colgroup="scope">
                            <col
                                v-for="field in scope.fields"
                                :key="field.key"
                                :style="{ width: getColWidth(field.key) }"
                            />
                        </template>

                        <template #cell()="data">

                            <template v-if="data.field.key === 'nomenclature_id'">
                                <!-- <b-form-select
                                    v-model="positions[0].nomenclature_id"
                                    :state="$v.sendData.$error ? $v.positions.$each[0].nomenclature_id.required : null"
                                    :options="nomenclaturesOptions"
                                /> -->
                                <v-select
                                    label="name"
                                    v-bind:value="selectedPosition && selectedPosition.name"
                                    :options="nomenclaturesOptions"
                                    :appendToBody="true"
                                    @search="getNomenclatureOptionsByQuery"
                                    @input="positions[0].nomenclature_id = ( $event && $event.id )"
                                >
                                    <template #no-options>
                                    Нет результатов.
                                    </template>
                                </v-select>
                            </template>

                            <template v-if="data.field.key === 'mnemocode'">
                                <v-select
                                    label="name"
                                    v-bind:value="selectedPosition && selectedPosition.mnemocode"
                                    :options="mnemocodesOptions"
                                    :appendToBody="true"
                                    @search="getNomenclatureOptionsByMnemocode"
                                    @input="positions[0].nomenclature_id = ( $event && $event.id )"
                                >
                                    <template #no-options>
                                    Нет результатов.
                                    </template>
                                </v-select>
                            </template>

                            <template v-if="data.field.key === 'unit'">
                                {{ selectedPosition && selectedPosition.units[0] && selectedPosition.units[0].name }}
                            </template>

                            <template v-if="data.field.key === 'price_without_vat'">
                                {{ selectedPosition && selectedPosition.price }}
                            </template>

                            <template v-if="data.field.key === 'new_price_without_vat'">
                                <b-form-input
                                    v-model.number="positions[0].new_price_without_vat"
                                    :state="$v.sendData.$error ? $v.positions.$each[0].new_price_without_vat.required : null"
                                    type="number"
                                    trim
                                />
                            </template>

                        </template>

                    </BTable>

                </div>
            </div>
        </div>

        <div class="mb-4">
            <div class="h4 mb-4">Комментарии</div>

            <div class="table-create">
                <div class="mt-3">

                    <BTable
                        :items="comments"
                        :fields="commentsFields"
                        :bordered="true"
                        empty-text="Нет комментариев"
                        striped
                        small
                        show-empty
                    >
                        <template #cell()="data">

                            <template v-if="data.field.key === 'id'">
                                {{ data.item.id }}
                            </template>

                            <template v-if="data.field.key === 'question_text'">
                                {{ data.item.question_text }}
                            </template>

                            <template v-if="data.field.key === 'question_date'">
                                {{ data.item && data.item.question_date && moment( data.item.question_date ).format( 'DD.MM.YYYY в HH:mm' ) }}
                            </template>

                            <template v-if="data.field.key === 'ask_text'">
                                <div class="d-flex m-n1"
                                    v-if="isAuthor && !data.item.ask_text"
                                >
                                    <div class="p-1 flex-grow-1 w-100">
                                        <b-form-input
                                            v-on:input="onInputAsk( data, $event )"
                                            type="text"
                                        />
                                    </div>

                                    <div class="p-1">
                                        <b-button variant="primary"
                                            v-on:click="updateComment( data )"
                                        >Отправить</b-button>
                                    </div>
                                </div>
                                <div
                                    v-else
                                >
                                    {{ data.item.ask_text }}
                                </div>
                            </template>

                            <template v-if="data.field.key === 'ask_date'">
                                {{ data.item && data.item.ask_date && moment( data.item.ask_date ).format( 'DD.MM.YYYY в HH:mm' ) }}
                            </template>

                        </template>
                    </BTable>

                </div>
            </div>
        </div>

        <b-modal
            id="confirm-delete"
            title="Удаление запроса на согласование цен"
            centered>
            <p class="m-0">Вы действительно хотите удалить запрос на согласование цен?</p>
            <template #modal-footer="{ hide }">
                <b-button @click="hide()">Нет</b-button>
                <b-button
                    variant="danger"
                    @click="deleteNegotiation">Да
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>

import {required} from "vuelidate/lib/validators"
import { mapState, mapGetters } from 'vuex'
import global from '@/utils/variables'
import DownloadLink from "@/components/UI/DownloadLink";
import { debounce } from "debounce";
import axios from 'axios';
import moment from 'moment';

export default {
    name: "PriceNegotiationForm",
    components: {DownloadLink},
    validations() {
        console.log(  )
        const workAgreementRules = (
            ( this.sendData.type === 'contract_home_method' )
                ? { required }
                : {}
        )

        if (this.isProvider) {
            return {
                sendData: {
                    type: {required},
                    organization_id: {required},
                    order_id: {},
                    work_agreement_id: workAgreementRules,
                    responsible_full_name: {required},
                    responsible_phone: {required},
                    comment: {required}
                },
                positions: {
                    $each: {
                        nomenclature_id: {required},
                        new_price_without_vat: {required}
                    }
                }
            }
        }
        if (this.isContractor) {
            return {
                sendData: {
                    type: {required},
                    object_id: {required},
                    provider_contr_agent_id: {required},
                    contractor_contr_agent_id: {required},
                    organization_id: {required},
                    order_id: {},
                    work_agreement_id: workAgreementRules,
                    responsible_full_name: {required},
                    responsible_phone: {required},
                    comment: {required}
                },
                positions: {
                    $each: {
                        nomenclature_id: {required},
                        new_price_without_vat: {required}
                    }
                }
            }
        }
    },
    props: {
        edit: Boolean
    },
    watch: {
        orders(val) {
            if (!val.filter(item => item.value === this.sendData.order_id).length) {
                // this.sendData.order_id = null
            }
        },
        'sendData.type': {
            handler: function() {
                this.orders = []
                this.watchForOrdersData()
            },
            immediate: true
        },
        'sendData.object_id' () { this.watchForOrdersData() },
        'sendData.sub_object_id' () { this.watchForOrdersData() },
        'sendData.provider_contr_agent_id' () { this.watchForOrdersData() },
        'sendData.contractor_contr_agent_id' () { this.watchForOrdersData() },
        'sendData.organization_id' ( value ) {
            this.watchForOrdersData()
            const organization_uuid = this.$store.state.referenceBooks.organizations.find( el => el.id == value ).uuid
            this.$store.dispatch( 'getWorkAgreements', { params: { organization_uuid } } )
        },
        'sendData.file'() {
            this.fileUrl = null
        },
        'sendData.files'() {
            // this.fileUrls = []
        }
    },
    computed: {
        selectedPosition() {
            if ( !this.nomenclaturesOptions.length )
                return
            const nomenclatureId = this.positions[0].nomenclature_id
            if ( !nomenclatureId )
                return
            return this.nomenclaturesOptions.find( el => el.id === nomenclatureId )
        },
        id () {
            return this.$route.params.id
        },
        ...mapState({
            user: state => state.account.user,
            negotiation: state => state.priceNegotiations.currentNegotiation
        }),
        ...mapGetters({
            isProvider: 'isProvider',
            isContractor: 'isContractor',
            objectsOptions: 'getObjectsOptions',
            subObjectsOptions: 'getSubObjectsOptions',
            contrAgentsOptions: 'getContrAgentsOptions',
            organizationsOptions: 'getOrganizationsOptions',
            workAgreementsOptions: 'getWorkAgreementsOptions',
            getCountriesOptions: 'getCountriesOptions'
        }),
        // nomenclatures() {
        //     let res = []
        //     if ( !this.sendData.order_id )
        //         return this.orders.reduce( ( acc, el ) => ( acc.concat( el.nomenclatures ) ), [] )
        //     this.orders.forEach((order) => {
        //         if (order.value === this.sendData.order_id) {
        //             res = order.nomenclatures
        //         }
        //     })
        //     return res
        // },
        nomenclaturesOptions: {
            get() {
                const existedsIds = []
                return this.nomenclatures.reduce( ( acc, el ) => {
                    if ( !existedsIds.includes( el.id ) ) {
                        acc.push( el )
                        existedsIds.push( el.id )
                    }
                    return acc
                }, [] )
            },
            set( value ) {
                this.nomenclatures = value
            }
        },
        mnemocodesOptions() {
            return this.nomenclaturesOptions.map( el => ( {
                ...el,
                name: el.mnemocode
            } ) )
        },
        selectedNomenclature() {
            let res = { units: [] }
            this.orders?.forEach(order => {
                order?.nomenclatures?.forEach(nomenclature => {
                    if (nomenclature.id === this.positions[0].nomenclature_id) {
                        res = nomenclature
                    }
                })
            })
            return res
        },
        getType() {
            switch (this.sendData.type) {
                case 'contract_work':
                    return 'Подрядные работы'
                case 'contract_home_method':
                    return 'Договор по хоз. способу'
                default:
                    return 'Не указано'
            }
        },
        isAuthor() {
            return this.negotiation?.creator_contr_agent_id == this.$store.state?.account?.user?.contr_agent_id
        }
    },
    data: () => ({
        nomenclatures: [],
        pageLoad: false,
        positionsFields: [
            {
                key: 'mnemocode',
                sortable: true,
                label: 'Мнемокод',
                full: 'Мнемокод',
                openDefault: true
            },
            {
                key: 'nomenclature_id',
                sortable: true,
                label: 'Номенклатура',
                full: 'Номенклатура',
                openDefault: true
            },
            {
                key: 'unit',
                sortable: true,
                label: 'Ед. изм.',
                full: 'Единица измерения',
                openDefault: true
            },
            {
                key: 'price_without_vat',
                sortable: true,
                label: 'Текущая цена без НДС',
                full: 'Текущая цена без НДС',
                openDefault: true
            },
            {
                key: 'new_price_without_vat',
                sortable: true,
                label: 'Новая цена без НДС',
                full: 'Новая цена без НДС',
                openDefault: true
            }
        ],
        sendData: {
            action: 'draft',
            type: 'contract_work',
            object_id: null,
            sub_object_id: null,
            provider_contr_agent_id: null,
            contractor_contr_agent_id: null,
            organization_id: null,
            order_id: null,
            work_agreement_id: null,
            country: null,
            responsible_full_name: null,
            responsible_phone: null,
            comment: null,
            file: null
        },
        positions: [
            {
                nomenclature_id: null,
                new_price_without_vat: null,
                current_price_without_vat: null
            }
        ],
        orders: [],
        fileUrl: null,
        apiUrl: global.API_URL,
        fileUrls: [],
        typeOptions: [
            {
                value: 'contract_work',
                text: 'Подрядные работы'
            },
            {
                value: 'contract_home_method',
                text: 'Договор по хоз. способу'
            }
        ],
        commentsFields: [
            {
                key: 'id',
                label: '№ комментария',
                full: '№ комментария',
                openDefault: true
            },
            {
                key: 'question_text',
                label: 'Текст комментария',
                full: 'Текст комментария',
                openDefault: true
            },
            {
                key: 'question_date',
                label: 'Дата комментария',
                full: 'Дата комментария',
                openDefault: true
            },
            {
                key: 'ask_text',
                label: 'Текст ответа',
                full: 'Текст ответа',
                openDefault: true
            },
            {
                key: 'ask_date',
                label: 'Дата ответа',
                full: 'Дата ответа',
                openDefault: true
            }
        ],
        comments: null,
        commentsAsks: {},
        moment,
        inProcess: false
    }),
    methods: {
        deleteNegotiation () {
            if (this.edit) {
                this.$store.dispatch('deletePriceNegotiation', this.id)
                    .then(() => {
                        this.$bvToast.toast('Запрос на согласование цен успешно удален', {
                            toaster: 'b-toaster-top-right',
                            solid: true,
                            appendToast: false,
                            variant: 'success'
                        })
                        this.$router.push('/price-negotiations')
                    })
                    .catch(() => {
                        this.$bvToast.toast('Что-то пошло не так, попробуйте позднее', {
                            toaster: 'b-toaster-top-right',
                            solid: true,
                            appendToast: false,
                            variant: 'danger'
                        })
                    })
            } else {
                this.$router.push('/price-negotiations')
            }
        },
        async getOrders(data) {
            let canSend = true
            // if (this.isContractor) {
            //     for (let key in data) {
            //         if (!data[key] && key !== 'sub_object_id') {
            //             canSend = false
            //         }
            //     }
            // }
            // if (this.isProvider) {
            //     if (!data.organization_id) {
            //         canSend = false
            //     }
            // }
            if (canSend) {
                await this.$store.dispatch('getPriceNegotiationOrders', data).then(resp => {
                    if (this.isContractor) {
                        this.orders = resp.map(item => {
                            return {
                            text: `${item.number} от ${this.$options.filters.parseDate(item.order_date)}`,
                            value: item.id,
                            nomenclatures: item?.positions?.map(position => {
                                if ( !position.nomenclature ) return null

                                return {
                                    id: position.nomenclature.id,
                                    name: position.nomenclature.name,
                                    price: position.price_without_vat,
                                    units: position.nomenclature.units || [],
                                    mnemocode: position.nomenclature.mnemocode
                                }
                            }).filter( el => el )
                        }
                        })
                    }
                    if (this.isProvider) {
                        this.orders = resp.map(item => ({
                            text: `${item.number} от ${this.$options.filters.parseDate(item.order_date)}`,
                            value: item.id,
                            nomenclatures: item?.actual_positions?.map(position => ({
                                id: position.nomenclature.id,
                                name: position.nomenclature.name,
                                price: position.price_without_vat,
                                units: position.nomenclature.units || [],
                                mnemocode: position.nomenclature.mnemocode
                            }))
                        }))
                    }

                })
            }
        },
        watchForOrdersData() {
            let data = {}
            if (this.sendData.type === 'contract_home_method') {
                data = {
                    type: this.sendData.type,
                    provider_contr_agent_id: this.sendData.provider_contr_agent_id,
                    organization_id: this.sendData.organization_id
                }
            }
            if (this.sendData.type === 'contract_work') {
                data = {
                    type: this.sendData.type,
                    object_id: this.sendData.object_id,
                    sub_object_id: this.sendData.sub_object_id,
                    provider_contr_agent_id: this.sendData.provider_contr_agent_id,
                    contractor_contr_agent_id: this.sendData.contractor_contr_agent_id,
                    organization_id: this.sendData.organization_id
                }
            }
            this.getOrders(data)
        },
        getColWidth(key) {
            switch (key) {
                case 'nomenclature_id':
                    return '500px'
                case 'unit':
                    return '180px'
                case 'new_price_without_vat':
                    return '180px'
                case 'price_without_vat':
                    return '180px'
                case 'mnemocode':
                    return '180px'
                default:
                    return 'auto'
            }
        },
        async createNegotiation (save = false) {
            if (this.$v.$invalid) {
                this.$v.$touch()
                this.$bvToast.toast('Заполните обязательные поля', {
                    toaster: 'b-toaster-top-right',
                    solid: true,
                    appendToast: false,
                    variant: 'danger'
                })
                return
            }

            this.inProcess = true            
            this.sendData.action = save ? 'draft' : 'approve'

            this.positions[0].current_price_without_vat = parseInt( this.nomenclaturesOptions.find( el => el.id === this.positions[0].nomenclature_id ).price )

            if (this.edit) {
                this.$store.dispatch('updatePriceNegotiation', {
                    id: this.id,
                    data: {
                        ...this.sendData,
                        positions: this.positions
                    }
                })
                    .then(() => {
                        this.sendSuccess('Успешно')
                    })
                    .catch(err => {
                        this.sendError(err)
                    })
                    .finally( () => {
                        this.inProcess = false
                    } )

                this.sendData.files?.forEach( el => {
                    const fd = new FormData()
                    fd.append( 'file', el )
                    axios.post( `price-negotiations/${ this.id }/files`, fd )
                } )
            } else {
                this.$store.dispatch('createPriceNegotiation', {
                    ...this.sendData,
                    positions: this.positions
                })
                    .then(() => {
                        this.sendSuccess('Успешно')
                    })
                    .catch(err => {
                        this.sendError(err)
                    })
                    .finally( () => {
                        this.inProcess = false
                    } )
            }
        },
        sendSuccess(successText) {
            this.$bvToast.toast(successText, {
                toaster: 'b-toaster-top-right',
                solid: true,
                appendToast: false,
                variant: 'success'
            })
            this.$router.push('/price-negotiations')
        },
        sendError(err) {
            if ( err.response.status === 422 ) {
                const errArray = err?.response?.data.errors
                const values = Object.values( errArray )
                if ( values.length ) {
                    values.forEach( el => {
                        this.$bvToast.toast(el, {
                            toaster: 'b-toaster-top-right',
                            solid: true,
                            appendToast: false,
                            variant: 'danger'
                        })
                    } )
                } else {
                    const errText = err.response?.message || 'Проверьте правильность заполненных полей.'
                    this.$bvToast.toast(errText, {
                        toaster: 'b-toaster-top-right',
                        solid: true,
                        appendToast: false,
                        variant: 'danger'
                    })
                }
            } else {
                this.$bvToast.toast('Ошибка сервера. Попробуйте позднее.', {
                    toaster: 'b-toaster-top-right',
                    solid: true,
                    appendToast: false,
                    variant: 'danger'
                })
            }
        },
        clearFile() {
            this.fileUrl = null
            this.sendData.file = null

            // this.fileUrls = []
            // this.sendData.files = []
        },

        getObjectsOptionsByQuery(search, loading) {
        if (search.length > 1) {
            loading(true);
            this.getObjectsOptionsSearch(search, loading, this);
        }
        },
        getObjectsOptionsSearch: debounce(async function (search, loading, vm) {
        axios
            .get("/references/objects", { params: { search } })
            .then((r) =>
            vm.$store.commit("setReferenceBooks", {
                book: "objects",
                data: r.data.data,
            })
            )
            .finally(() => loading(false));
        }, 800),

        getContrAgentsOptionsByQuery(search, loading) {
        if (search.length > 1) {
            loading(true);
            this.getContrAgentsOptionsSearch(search, loading, this);
        }
        },
        getContrAgentsOptionsSearch: debounce(async function (search, loading, vm) {
        axios
            .get("/references/contr_agents", { params: { name: search } })
            .then((r) =>
            vm.$store.commit("setReferenceBooks", {
                book: "contrAgents",
                data: r.data.data.data,
            })
            )
            .finally(() => loading(false));
        }, 800),

        getOrganizationsOptionsByQuery(search, loading) {
        if (search.length > 1) {
            loading(true);
            this.getOrganizationsOptionsSearch(search, loading, this);
        }
        },
        getOrganizationsOptionsSearch: debounce(async function (
        search,
        loading,
        vm
        ) {
        axios.get( '/references/organizations', { params: { name: search } } )
            .then( r =>
            vm.$store.commit( 'setReferenceBooks', {
                book: 'organizations',
                data: r.data.data
            }
            ) )
            .finally( () => loading( false ) )
        },
        800),

        getWorkAgreementsOptionsByQuery(search, loading) {
        if (search.length > 1) {
            loading(true);
            this.getWorkAgreementsOptionsSearch(search, loading, this);
        }
        },
        getWorkAgreementsOptionsSearch: debounce(async function (
        search,
        loading,
        vm
        ) {
        axios.get( '/references/work_agreements', { params: { number: search } } )
            .then( r =>
            vm.$store.commit( 'setReferenceBooks', {
                book: 'workAgreements',
                data: r.data.data
            }
            ) )
            .finally( () => loading( false ) )
        },
        800),

        getNomenclatureOptionsByQuery(search, loading) {
        if (search.length > 1) {
            loading(true);
            this.getNomenclatureOptionsSearch(search, loading, this);
        }
        },
        getNomenclatureOptionsSearch: debounce(async function (
        search,
        loading,
        vm
        ) {
        vm.$store
            .dispatch("getNomenclatureSearch", search)
            .then((resp) => {
            this.nomenclaturesOptions = resp;
            })
            .then(() => loading(false))
            .catch(() => loading(false));
        },
        800),

        getNomenclatureOptionsByMnemocode(search, loading) {
        if (search.length > 1) {
            loading(true);
            this.getNomenclatureOptionsSearch2(search, loading, this);
        }
        },
        getNomenclatureOptionsSearch2: debounce(async function (
        search,
        loading
        ) {
            ( new Promise( resolve => {
                axios.get('/references/nomenclature', {
                    params: {
                        pagination: 'off',
                        mnemocode: search
                    }
                })
                    .then( res => resolve( res.data.data ) )
            } ) )
            .then((resp) => {
            this.nomenclaturesOptions = resp;
            })
            .then(() => loading(false))
            .catch(() => loading(false));
        },
        800),

        async delFile( file ) {
            try {
                await axios.delete( `/price-negotiations/${ file.price_negotiation_id }/files/${ file.id }` )
                this.fileUrls = this.fileUrls.filter( el => el !== file )
            } catch( error ) {
                error
            }
        },

        onInputAsk( comment, value ) {
            this.commentsAsks[ comment.item.id ] = value
        },

        async updateComment( data ) {
            const ask = this.commentsAsks[ data.item.id ]
            try {
                await axios.post(
                    `price-negotiations/${ this.id }/comments/${ data.item.id }/ask`,
                    {
                        text: ask
                    }
                )
                
                const response = await axios.get( `/price-negotiations/${ this.id }/comments` )
                this.comments = response.data.data
            } catch( error ) {
                error
            }
        }
    },
    async mounted() {
        try {
            const data = await this.$store.dispatch( 'getNomenclatureSearch' )
            this.nomenclatures = data
            if (!this.edit) {
                if (this.isContractor) {
                    this.sendData.contractor_contr_agent_id = this.user?.contr_agent?.id
                    this.sendData.type = 'contract_work'
                }

                if (this.isProvider) {
                    this.sendData.provider_contr_agent_id = this.user?.contr_agent?.id
                    this.sendData.type = 'contract_home_method'
                }
                
                const user = this.$store.state.account.user
                this.sendData.responsible_full_name = user.name
                this.sendData.responsible_phone = user.phone
            }

            if (this.edit) {
                await this.$store.dispatch('getCurrentNegotiation', this.id).catch(err => {
                    if (err.response.status === 404) {
                        this.$router.push('/price-negotiations')
                    }
                })

                this.sendData.type = this.negotiation.type
                this.sendData.object_id = this.negotiation?.order?.customer?.object_id
                this.sendData.sub_object_id = this.negotiation?.order?.customer?.sub_object_id
                this.sendData.provider_contr_agent_id = this.negotiation?.order?.provider?.contr_agent_id
                this.sendData.contractor_contr_agent_id = this.negotiation?.order?.contractor?.contr_agent_id
                this.sendData.organization_id = this.negotiation?.order?.customer?.organization_id
                if ( !this.negotiation?.order?.is_generated )
                    this.sendData.order_id = this.negotiation.order_id
                this.sendData.work_agreement_id = this.negotiation.work_agreement_id
                this.sendData.responsible_full_name = this.negotiation.responsible_full_name
                this.sendData.responsible_phone = this.negotiation.responsible_phone
                this.sendData.comment = this.negotiation.comment
                this.sendData.country = this.negotiation.country

                this.positions = this.negotiation.positions.map(position => ({
                    nomenclature_id: position.nomenclature_id,
                    new_price_without_vat: position.new_price_without_vat,
                    current_price_without_vat: position.current_price_without_vat
                }))

                this.fileUrl = this.negotiation.file_url
                this.fileUrls = this.negotiation.files

                const response = await axios.get( `/price-negotiations/${ this.id }/comments` )
                this.comments = response.data.data
            }
        } finally {
            this.pageLoad = true;
        }
    }
}
</script>

<style
    lang="sass"
    scoped
>

</style>
